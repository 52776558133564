import http from "@/services/http-common";

class dataLanding {
	getRgmx() {
		return http.get("/single-pages/3");
	}

	getBanners() {
		return http.get("/banners/?isGlobal=false&category.slug=homepage");
	}
}

export default new dataLanding();
