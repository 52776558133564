<template>
	<div class="rgmx">
		<div
			class="verde text-center image-post "
			align-v="center"
			:style="
				`background: url(${path.storage_files +
					landing.cover_image.url}); height:360px`
			"
		>
			<div
				class="gradient-black "
				style="height: 100%; justify-content: center; align-content: center;"
			>
				<h1 class="display-4 white-text strong text-shadow">
					{{ landing.title }}
				</h1>
			</div>
		</div>

		<b-container class="mt-5">
			<!-- <b-row class="mb-4" align-v="center">
				<b-col cols="12">
					<h1 class="display-4  super-strong">Vinculación : Eventos</h1>
					<hr class="line" />
				</b-col>
			</b-row> -->

			<b-row class="mb-5">
				<b-col col> &nbsp; </b-col>
				<b-col col class="rojo text-center text-white p-5">
					<h3 class="light">
						<a
							href="/vinculacion/rgmx/lineamientos"
							target="_blank"
							class="text-white"
						>
							Lineamientos de la <br />
							<strong>Red Global MX</strong>
						</a>
					</h3>
				</b-col>
				<b-col col> &nbsp; </b-col>
			</b-row>

			<b-row class="mb-5">
				<b-col col>
					<vue-simple-markdown
						:source="`${landing.paragraph}`"
					></vue-simple-markdown>
				</b-col>
			</b-row>
		</b-container>
	</div>
</template>

<script>
import dataLanding from "@/services/data-landing";
import PaginationComponent from "@/views/events/_Pagination.vue";

export default {
	name: "LandingRgmxView",
	components: {
		PaginationComponent,
	},

	props: {
		cfgTitle: String,
	},

	data() {
		return {
			landing: {},
			eventsMeta: {
				pagination: {
					page: 1,
				},
			},
			path: {
				base_url: process.env.VUE_APP_BASE_URL,
				endpoint: process.env.VUE_APP_ENDPOINT,
				storage_files: process.env.VUE_APP_STORAGE_FILES,
			},
		};
	},

	computed: {
		currentPage() {
			let page = this.$route.query.page;
			return page;
		},
	},

	mounted() {
		document.title =
			"Red Global MX | Instituto de los Mexicanos en el Exterior";
		this.loadLanding();
		//this.$route
	},

	methods: {
		loadLanding() {
			dataLanding.getRgmx().then(
				(response) => {
					this.landing = response.data;
					console.log("🚀 ~ loadLanding ~ this.landing:", this.landing);
				},
				(error) => {
					this.landing =
						(error.response && error.response.data) ||
						error.message ||
						error.toString();
				}
			);
		},
	},
};
</script>
