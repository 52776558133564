<template>
  <section class="mb-5">
    <b-row>
      <b-col cols md="12" class=" text-center ">
        <b-button
          :disabled="page === 1"
          x-small
          class="mx-2"
          outlined
          fab
          :class="cfgColor"
          :href="`?page=1`"
        >
          <i class="fa-solid fa-angles-left"></i>
        </b-button>
        <b-button
          :disabled="page === 1"
          x-small
          class="mx-1"
          outlined
          fab
          :class="cfgColor"
          :href="`?page=${page - 1}`"
        >
          <i class="fa-solid fa-chevron-left"></i>
        </b-button>

        <span class="text-button mx-3">
          Página <strong>{{ page }}</strong> de
          <strong>{{ pageCount }} </strong>
        </span>

        <b-button
          :disabled="page === pageCount"
          x-small
          class="mx-1"
          outlined
          fab
          :class="cfgColor"
          :href="`?page=${page + 1}`"
        >
          <i class="fa-solid fa-chevron-right"></i>
        </b-button>
        <b-button
          :disabled="page === pageCount"
          x-small
          class="mx-1"
          outlined
          fab
          :class="cfgColor"
          :href="`?page=${pageCount}`"
        >
          <i class="fa-solid fa-angles-right"></i>
        </b-button>
      </b-col>

      <b-col cols md="12" class="text-center mt-2">
        <span class="">
          <strong>{{ total }}</strong> Eventos en total
        </span>
      </b-col>
      <b-col>
        <pre></pre>
      </b-col>
    </b-row>
  </section>
</template>
<script>
export default {
  name: "PaginationComponent",
  props: {
    page: {
      type: Number,
      default: 1,
    },
    pageSize: {
      type: Number,
      default: 25,
    },
    pageCount: {
      type: Number,
      default: 25,
    },
    total: {
      type: Number,
      default: 1,
    },
    cfgColor: {
      type: String,
      default: "blue-grey darken-2",
    },
  },

  computed: {
    currentPage() {
      let page = this.$route.query.page;
      return page;
    },
  },
};
</script>
